<template>
  <div class="text-no-wrap">
    <span class="ml-n10 pl-10 relative-counter-icon">
      <div class="change-counter-icon" v-if="showArrow">
        <v-icon size="18" :class="classes">$arrowTopIcon</v-icon>
      </div>
      
      {{ isCurrency ? currency(counter) : counter }}
    </span>
    <span v-if="!!cap">/ {{ isCurrency ? currency(cap) : cap }}</span>
  </div>
</template>

<script>
import currency from '@/mixins/currency'

export default {
  name: 'AnimationChangeCounter',
  inheritAttrs: false,
  mixins: [ currency ],
  props: {
    counter: {
      type: String | Number,
      default: 0
    },
    cap: {
      type: String | Number,
      default: 0
    },
    isCurrency: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showArrow: false,
    classes: 'yellowLight',
    firstLoad: true
  }),
  methods: {
    handlerShowArrow() {
      this.showArrow = true
      setTimeout(() => {
        this.showArrow = false
      }, 3000)
    }
  },
  watch: {
    counter: {
      handler(counter, oldCounter) {
        if (counter !== oldCounter) {
          this.classes = (counter - oldCounter) > 0 ? 'yellowLight--text' : 'pink--text rotate-180'

          if (!this.firstLoad) {
            this.handlerShowArrow()
          }
          this.firstLoad = false
        }
      }
    }
  }
}
</script>

<style lang="scss">
  .relative-counter-icon {
    position: relative;
    
    & > .change-counter-icon {
      position: absolute;
      left: 0;
    }
  }
</style>
